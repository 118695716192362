angular.module("vgresiduos").controller("DisposalIntegrationsCalculatorCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"asyncTimeoutService",
	function ($scope, $rootScope, dialogService, asyncTimeoutService) {
		"use strict";

		$scope.systems = [];
		const statusEnum = Vgr.enumerations.destination.systemStatus;

		function hasIntegrations() {
			return $scope.hasIntegrations();
		}

		function isSolicitationStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Solicitation;
		}

		function isGatheringStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Gathering;
		}

		function isTrackingStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Tracking;
		}

		function isTemporaryStorageStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.InTemporaryStorage;
		}

		function isArrivalStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Arrival;
		}

		function isConfirmationStage() {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Confirmation;
		}

		this.$onInit = function () {
			createIntegrationSystemsModels();
			if (hasIntegrations() && !$scope.isCurrentStageReview()) {
				if (isArrivalStage() || (isConfirmationStage() && !$scope.systems[0].IsUpToDate)) {
					openIntegrationsInfoPopup();
				}
			}
		};

		function createIntegrationSystemsModels() {
			$scope.systems = [];
			const groupedManifests = $scope.destinationModel.Manifests.reduce((grouped, manifest) => {
				grouped[manifest.Source.Id] = grouped[manifest.Source.Id] || [];
				grouped[manifest.Source.Id].push(manifest);
				return grouped;
			}, []);
			for (const index in groupedManifests) {
				const groupedManifest = groupedManifests[index];
				if (groupedManifest[0].Source.Id == Vgr.enumerations.destination.systemSource.Local) {
					continue;
				}
				$scope.systems.push({
					SourceId: groupedManifest[0].Source.Id,
					Description: groupedManifest[0].Source.Description,
					ErrorMessage: null,
					Manifests: groupedManifest.map((e) => buildIntegrationSystemModel(e))
				});
			}
			return $scope.systems;
		}

		function buildIntegrationSystemModel(manifest) {
			return {
				SourceId: manifest.Source.Id,
				ManifestId: manifest.Id,
				Description: manifest.Source.Description,
				Status: manifest.Status,
				StatusDescription: $scope.getSystemStatusDescription(manifest.Status, false, manifest.Source),
				IsUpToDate: isUpToDate(manifest),
				IsUpToDateFunc: isUpToDate,
				Errors: manifest.Errors,
				MtrCode: manifest.MtrCode,
				ProvisionalMtrCode: manifest.ProvisionalMtrCode,
				IsAutomaticProvisional: manifest.IsAutomaticProvisional,
				IntegrationsMtrId: manifest.IntegrationsMtrId,
				actions: {
					canCancel: hasMtrAction(Vgr.enumerations.destination.systemActions.Cancel, manifest.Source.DisabledActions),
					canUpdateStatus: hasMtrAction(
						Vgr.enumerations.destination.systemActions.UpdateStatus,
						manifest.Source.DisabledActions
					),
					canViewDetails: hasMtrAction(
						Vgr.enumerations.destination.systemActions.ViewDetails,
						manifest.Source.DisabledActions
					)
				}
			};
		}

		function isUpToDate(manifest) {
			if (manifest.Status == statusEnum.Provisional) {
				return false;
			} else if (isSolicitationStage() || isGatheringStage() || isTrackingStage()) {
				return true;
			} else if (isTemporaryStorageStage()) {
				return (
					manifest.Status == statusEnum.ReceivedTemporaryStorage ||
					manifest.Status == statusEnum.Received ||
					manifest.Status == statusEnum.Disposed ||
					manifest.Status == statusEnum.ComplementaryMTREmitted
				);
			} else if (isArrivalStage()) {
				return manifest.Status == statusEnum.Received || manifest.Status == statusEnum.Disposed;
			} else if (isConfirmationStage()) {
				return manifest.Status == statusEnum.Disposed;
			}
		}

		function hasMtrAction(systemActionId, disabledActions) {
			return disabledActions.indexOf(systemActionId) === -1;
		}

		$scope.getSystemStatusDescription = function (status, isDetailed, system) {
			let statusDescription = isDetailed
				? $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_STATUS_DETAILED[status]
				: $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_STATUS[status];
			let disabledActions = system ? system.DisabledActions : [];

			if (status == Vgr.enumerations.destination.systemStatus.Created) {
				if (hasMtrAction(Vgr.enumerations.destination.systemActions.UpdateStatus, disabledActions)) {
					statusDescription = statusDescription.replace("[SUPPLIER_TYPE]", getTypeSupplier(system));
				} else {
					statusDescription = $scope.labels.INTEGRATION_CARRIED_OUT;
				}
			}
			return statusDescription;
		};

		function getTypeSupplier(system) {
			if (system && system.Id == Vgr.enumerations.destination.systemSource.SGA_PR) {
				return $scope.labels.DESTINATOR.toLowerCase();
			}

			return $scope.isTemporaryStorage()
				? $scope.labels.TEMPORARY_STORAGER.toLowerCase()
				: $scope.labels.DESTINATOR.toLowerCase();
		}

		$scope.isUpToDate = function (manifest) {
			return isUpToDate(manifest);
		};

		$scope.hasAnySystemPending = function () {
			return $scope.systems.find((system) => !system.IsUpToDate);
		};

		$scope.showIntegrationsPopup = function () {
			return hasIntegrations();
		};

		$scope.showAddIntegrationsPopup = function () {
			if ($scope.showIntegrationsPopup()) {
				return false;
			}
			if (!$scope.destinationModel.Id) {
				return false;
			}
			if (!$scope.editPermission) {
				return false;
			}
			if ($scope.isOriginHistoryThatCantUseMtr()) {
				return false;
			}
			if (!$scope.clientExternalSystems || $scope.clientExternalSystems.length == 0) {
				return false;
			}
			if (
				!$scope.unlockDisposalGatheringPermission &&
				$scope.destinationModel.LockedGathering &&
				$scope.disposalPreferences.needUnlockDisposalGatheringStage &&
				$scope.destinationModel.Status == Vgr.enumerations.destination.stages.Gathering
			) {
				return false;
			}

			return true;
		};

		$scope.openIntegrationsPopup = function () {
			$scope.UpdateIntegrationSystemsModels();

			const params = {
				localVariables: {
					destinationModel: $scope.destinationModel,
					readOnlyModel: $scope.readOnlyModel,
					systems: $scope.systems,
					loadings: $scope.loadings,
					listClientExternalSystems: $scope.listClientExternalSystems,
					updateIntegrationSystemsModels: $scope.UpdateIntegrationSystemsModels,
					editPermission: $scope.editPermission,
					integrationsInfoPopupEnabled: $scope.integrationsInfoPopupEnabled,
					clientExternalSystems: $scope.clientExternalSystems,
					getSystemStatusDescription: $scope.getSystemStatusDescription,
					onUpdateDisposalManifest: $scope.onUpdateDisposalManifest,
					listenForDisposalFilesEvents: $scope.listenForDisposalFilesEvents,
					isUpToDate: $scope.isUpToDate,
					closeIntegrationStatusAndOpenInfoPopup: $scope.closeIntegrationStatusAndOpenInfoPopup,
					openIntegrationsPopup: $scope.openIntegrationsPopup,
					disableNewIntegrations: $scope.disableNewIntegrations()
				}
			};

			const dialog = dialogService.showDialogFromTemplateV2(
				"views/destination/popups/disposalIntegrationsPopup/disposalIntegrationsPopup_template.html",
				"DisposalIntegrationsPopupCtrl",
				"#disposal-popup-medium",
				params,
				true
			);

			return ($scope.dialog = dialog);
		};

		$scope.openAddIntegrationsPopup = function (systemsToForceIntegration) {
			const params = {
				localVariables: {
					destinationModel: $scope.destinationModel,
					readOnlyModel: $scope.readOnlyModel,
					systems: $scope.systems,
					listClientExternalSystems: $scope.listClientExternalSystems,
					updateIntegrationSystemsModels: $scope.UpdateIntegrationSystemsModels,
					forcedAutoSelectSystems: systemsToForceIntegration,
					loadings: $scope.loadings,
					openIntegrationsPopup: $scope.openIntegrationsPopup,
					disableNewIntegrations: $scope.disableNewIntegrations()
				}
			};

			const dialog = dialogService.showDialogFromTemplateV2(
				"views/destination/popups/disposalAddIntegrationsPopup/disposalAddIntegrationsPopup_template.html",
				"DisposalAddIntegrationsPopupCtrl",
				"#disposal-popup-medium",
				params,
				true
			);

			return ($scope.dialog = dialog);
		};

		$scope.UpdateIntegrationSystemsModels = function () {
			return createIntegrationSystemsModels();
		};

		const stageWatch = $scope.$watch("destinationConfig.currentStage.Id", function (newValue, oldValue) {
			if (newValue != oldValue) {
				createIntegrationSystemsModels();
				if (hasIntegrations() && !$scope.isCurrentStageReview()) {
					if (isArrivalStage() || (isConfirmationStage() && !$scope.systems[0].IsUpToDate)) {
						openIntegrationsInfoPopup();
					}
				}
			}
		});

		$scope.integrationsInfoPopupEnabled = function () {
			return $scope.editPermission && !$scope.isToBlockFieldsBasedOnDisposalDate && hasIntegrations();
		};

		function openIntegrationsInfoPopup(system) {
			if ($scope.integrationsInfoPopupEnabled()) {
				let systemsToPopup = [];
				if (system) {
					systemsToPopup = [system];
				} else {
					systemsToPopup = $scope.systems;
				}
				if (!systemsToPopup.length) {
					return;
				}

				const params = {
					localVariables: {
						destinationModel: $scope.destinationModel,
						systemsToPopup: systemsToPopup,
						editPermission: $scope.editPermission,
						readOnlyModel: $scope.readOnlyModel,
						getSystemStatusDescription: $scope.getSystemStatusDescription,
						onUpdateDisposalManifest: $scope.onUpdateDisposalManifest,
						listenForDisposalFilesEvents: $scope.listenForDisposalFilesEvents
					}
				};

				const dialog = dialogService.showDialogFromTemplateV2(
					"views/destination/popups/disposalIntegrationsInfoPopup/disposalIntegrationsInfoPopup_template.html",
					"DisposalIntegrationsInfoPopupCtrl",
					"#disposal-popup",
					params,
					true
				);
				return ($scope.dialog = dialog);
			}
		}

		$scope.closeIntegrationStatusAndOpenInfoPopup = function (system) {
			dialogService.hide($scope.dialog);
			openIntegrationsInfoPopup(system);
		};

		$scope.onUpdateDisposalManifest = function (sourceId, oldStatus, newStatus) {
			// when DisposalManifest Status is updated to received or disposed
			// files are attached to Disposal Files
			// this IF compare whether the DisposalManifest has changed status to received or disposed
			if (oldStatus !== newStatus && isStatusReceived(newStatus)) {
				const roomName = `disposal_${$scope.destinationModel.Id}_source_${sourceId}_files_updated`;
				const timeout = asyncTimeoutService.getTimeoutBySystem(sourceId);

				// an update event is fired to update the already loaded list of disposal.Files
				$scope.listenForDisposalFilesEvents(roomName, sourceId, timeout);
			}
		};

		function isStatusReceived(status) {
			const receivedStatus = [
				Vgr.enumerations.destination.systemStatus.Received,
				Vgr.enumerations.destination.systemStatus.Disposed
			];
			return receivedStatus.includes(status);
		}

		const onOpenDisposalAddIntegrationsPopup = $rootScope.$on(
			Vgr.constants.evtOpenDisposalAddIntegrationsPopup,
			function (_event, disposalId) {
				if (disposalId != $scope.destinationModel.Id) {
					return;
				}

				$scope.openAddIntegrationsPopup(getSelectedSystemsToForceIntegration());
			}
		);

		function getSelectedSystemsToForceIntegration() {
			if ($scope.destinationModel.ExternalSystems) {
				return $scope.destinationModel.ExternalSystems.filter((s) => s.Selected).map((fs) => fs.SourceId);
			}
			return [];
		}

		$scope.$on("$destroy", function () {
			// disable the listener
			stageWatch();
			onOpenDisposalAddIntegrationsPopup();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
