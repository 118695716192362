angular.module("vgresiduos").controller("ClientEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"$q",
	"$state",
	"httpService",
	"clientService",
	"locationService",
	"dialogService",
	"additionalPropertyUtilService",
	"permissionService",
	"accountService",
	"userAccessService",
	function (
		$rootScope,
		$scope,
		$stateParams,
		$q,
		$state,
		httpService,
		clientService,
		locationService,
		dialogService,
		additionalPropertyUtilService,
		permissionService,
		accountService,
		userAccessService
	) {
		"use strict";

		$scope.clientId = $stateParams.id;
		const createPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterCreateEditClientData);
		const inactivatePermission = createPermission && !accountService.isMasterClient();

		const editPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditUnit, $scope.clientId);
		const hasOtherClientWithPermission = permissionService.hasOtherClientWithPermission(Vgr.systemPermissions.EditUnit);

		const loggedClientId = accountService.getCurrentClient().id;
		const isSupplier = clientService.isSupplier();

		$scope.isLowerGenerationPlan = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.lowerGenerationPlan,
			null
		);

		$scope.myfiles = {
			files: []
		};

		$scope.isCreation = !$scope.clientId;
		$scope.enableDisposalPreferencesTab = !$scope.isCreation && loggedClientId == $scope.clientId && !isSupplier;

		this.$onInit = function () {
			if ($scope.clientId && loggedClientId != $scope.clientId) {
				$state.go("unitList");
			} else {
				$scope.loading = true;
				loadClient()
					.then(setInitialTab)
					.finally(function () {
						$scope.loading = false;
					});

				$rootScope.contentNavBar = $scope.saveOptions;
				$rootScope.contentNavBarGrid = false;
			}
		};

		function setInitialTab() {
			addReplicateCustomButtons();

			$scope.selectedTabIndex = getSelectedTabIndex();

			const tabsPage = Vgr.enumerations.clientEdit.tabPage;
			if ([tabsPage.general, tabsPage.additionalData, tabsPage.production].includes($scope.selectedTabIndex)) {
				$scope.clientGeneralTabSelectedClick();
			} else if ($scope.selectedTabIndex == tabsPage.disposalPreferences && $scope.enableDisposalPreferencesTab) {
				$scope.clientDisposalPreferencesTabSelectedClick();
			} else if ($scope.selectedTabIndex == tabsPage.storagePreferences && $scope.enableDisposalPreferencesTab) {
				$scope.clientStoragePreferencesTabSelectedClick();
			} else if ($scope.selectedTabIndex == tabsPage.integrations && !$scope.isCreation) {
				$scope.tabNoButtons();
			} else if ($scope.selectedTabIndex == tabsPage.stockControlPreferences && !$scope.isCreation) {
				$scope.clientStockControlPreferencesTabSelectedClick();
			} else {
				// when tab value is not in conditions set to the first tab index
				$scope.selectedTabIndex = tabsPage.general;
				$scope.clientGeneralTabSelectedClick();
			}
		}

		function getSelectedTabIndex() {
			if ($stateParams.tab && Vgr.util.isNumeric($stateParams.tab)) {
				return parseInt($stateParams.tab);
			}
			return Vgr.enumerations.clientEdit.tabPage.general;
		}

		function hasPermission() {
			if ($scope.client.ID) {
				return editPermission || inactivatePermission;
			} else {
				return createPermission;
			}
		}

		function getSaveOptions(client) {
			const saveOptions = {
				hasPermission: hasPermission
			};

			saveOptions.client = client;
			saveOptions.valid = true;
			saveOptions.returnState = "unitList";

			const fileOptions = {
				update: updateClientLogo,
				get: getClientLogo
			};

			let clientId = client !== null ? client.ID : undefined;

			function updateClientLogo(newFiles, deletedIds) {
				if (newFiles && newFiles.length) {
					const dto = {
						clientId: clientId,
						files: newFiles
					};
					return httpService.postDTOToServiceV2(clientService.updateLogo, dto);
				} else if (deletedIds) {
					return httpService.postDTOToServiceV2(clientService.deleteLogo, clientId);
				} else {
					const defer = $q.defer();
					defer.resolve();
					return defer.promise;
				}
			}

			function getClientLogo() {
				const defer = $q.defer();

				if (!clientId) {
					defer.resolve([]);
					return defer.promise;
				}

				httpService.getDTOFromServiceV2(clientService.getLogo, clientId).then(
					function (data) {
						if (data != null && data.ID == null) {
							data.ID = Vgr.util.uuid();
						}
						const list = [data];
						defer.resolve(list);
					},
					function () {
						defer.reject();
					}
				);

				return defer.promise;
			}

			saveOptions.fileOptions = fileOptions;

			saveOptions.save = function () {
				const deferred = $q.defer();
				const client = saveOptions.client;

				if (client.ID) {
					updateClient().then(
						function () {
							fileOptions.updateFiles().then(
								function () {
									deferred.resolve();
									$state.go(
										"clientEdit",
										{
											id: client.ID,
											tab: undefined
										},
										{
											reload: true
										}
									);
								},
								function () {
									deferred.reject();
								}
							);
						},
						function () {
							deferred.reject();
						}
					);
				} else {
					createClient().then(
						function (createdClientId) {
							clientId = createdClientId;
							fileOptions.updateFiles().then(
								function () {
									accountService.changeClient(clientId).then(
										function () {
											window.location.reload();
										},
										function () {
											deferred.reject();
										}
									);
								},
								function () {
									deferred.reject();
								}
							);
						},
						function () {
							deferred.reject();
						}
					);
				}

				return deferred.promise;
			};

			saveOptions.onSaveError = function () {};

			saveOptions.validate = function () {
				const valid = saveOptions.valid;

				if (!valid) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
					return false;
				}

				if (!saveOptions.client.City) {
					if (saveOptions.client.countryId == Vgr.constants.country.Brazil) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.CITY_VALIDATION_ERROR_MESSAGE);
					} else {
						$rootScope.$broadcast(
							Vgr.constants.evtShowErrorMessage,
							$rootScope.labels.CITY_VALIDATION_ERROR_MESSAGE_ES
						);
					}
					return false;
				}

				return true;
			};

			return saveOptions;
		}

		function createClient() {
			const deferred = $q.defer();

			const dto = getCreateClientDto();

			httpService.postDTOToServiceV2(clientService.createClient, dto).then(
				function (data) {
					deferred.resolve(data.ClientId);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function updateClient() {
			const deferred = $q.defer();

			const model = {
				id: $scope.client.ID,
				dto: getUpdateClientDto()
			};

			httpService.postDTOToServiceV2(clientService.updateClient, model).then(
				function () {
					httpService.getDTOFromServiceV2(locationService.getCity, $scope.client.CityId).then(function (cityResponse) {
						const city = {
							id: $scope.client.CityId,
							description: cityResponse.name,
							state: {
								countryId: cityResponse.state.countryId,
								description: cityResponse.state.name,
								id: cityResponse.state.id
							}
						};
						accountService.updateLoggedClientCachedInfo(model.dto.Name, city);
						$rootScope.$broadcast(Vgr.constants.evtChangeLoggedClientInfo);
					});
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function getCreateClientDto() {
			const dto = getBaseClientDto();

			dto.IsSupplier = $scope.client.IsSupplier;
			dto.OrganizationId = $scope.client.Organization ? $scope.client.Organization.Id : null;
			dto.OrganizationName = $scope.client.Organization
				? $scope.client.Organization.Name
				: $scope.client.OrganizationSearchText;

			return dto;
		}

		function getUpdateClientDto() {
			const dto = getBaseClientDto();

			dto.IsActive = $scope.client.IsActive;
			dto.AdditionalInformations = additionalPropertyUtilService.buildAdditionalPropertiesFromGridToRequest(
				$scope.client.InformationsFormated
			);

			return dto;
		}

		function getBaseClientDto() {
			const client = $scope.client;

			return {
				SocialName: client.SocialName,
				Name: client.Name,
				CNPJ: client.CNPJ,
				Phone: client.Telephone,
				CellPhone: client.CellPhone,
				ResponsibleEmail: client.Email.trim(),
				ResidueMarketEmail: client.ResidueMarketEmail,
				Address: client.Address,
				Neighborhood: client.Neighborhood,
				Cep: client.CEP,
				CityId: client.City.Id,

				MunicipalRegistration: client.CityRegistrationNumber,
				StateRegistration: client.StateRegistrationNumber,
				TotalArea: client.TotalArea,
				Cnae: client.ActivityCode,

				ResponsibleUsersIds: client.ResponsibleUsers,
				TechnicalContactUserId: client.SelectedTechnicalContact ? client.SelectedTechnicalContact.id : "",

				ShiftHours: client.ShiftHours,
				ShiftDays: client.ShiftDays,
				ShiftMonths: client.ShiftMonths,
				ProductionEmployeeNumber: client.ProductionEmployeeNumber,
				AdministrativeEmployeeNumber: client.AdministrativeEmployeeNumber,
				OtherAreasEmployeeNumber: client.OtherAreasEmployeeNumber,
				ActivityDescription: client.ActivityDescription
			};
		}

		function loadClient() {
			const deferred = $q.defer();

			if ($scope.clientId && !isNaN($scope.clientId)) {
				clientService.getClientById($scope.clientId).then(
					function (response) {
						const data = response.data;

						if (data.success) {
							$scope.client = data.content;
							Vgr.date.fromISOStringsToDate($scope.client);
							$scope.saveOptions = getSaveOptions($scope.client);
							$rootScope.contentNavBar = $scope.saveOptions;
							$scope.savePermission = hasPermission();
							$rootScope.contentNavBar.title = "Unidades";
							$rootScope.contentNavBar.breadcrumb = $scope.client.Code + " - " + $scope.client.Name;
							$rootScope.contentNavBarGrid = false;
						} else {
							$state.go("unitList");
							Vgr.util.showServerErrorMessage($rootScope, data);
						}

						deferred.resolve();
					},
					function () {
						$scope.client = {};
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_CONNECTION_ERROR);
						deferred.reject();
					}
				);
			} else {
				$scope.client = {};
				$scope.saveOptions = getSaveOptions($scope.client);
				$rootScope.contentNavBar = $scope.saveOptions;
				$scope.savePermission = hasPermission();
				$rootScope.contentNavBar.title = "Unidades";
				$rootScope.contentNavBarGrid = false;
				deferred.resolve();
			}

			return deferred.promise;
		}

		$scope.clientGeneralTabSelectedClick = function () {
			$scope.clientGeneralTabSelected = true;
			$scope.clientDisposalPreferencesTabSelected = false;
			$scope.clientStoragePreferencesTabSelected = false;
			$scope.clientStockControlPreferencesTabSelected = false;

			$rootScope.contentNavBar.buttons = [$rootScope.labels.SAVE];
		};

		$scope.tabNoButtons = function () {
			$scope.clientGeneralTabSelected = false;
			$scope.clientDisposalPreferencesTabSelected = false;
			$scope.clientStoragePreferencesTabSelected = false;
			$scope.clientStockControlPreferencesTabSelected = false;

			$rootScope.contentNavBar.buttons = [];
		};

		$scope.clientDisposalPreferencesTabSelectedClick = function () {
			$scope.clientGeneralTabSelected = false;
			$scope.clientDisposalPreferencesTabSelected = true;
			$scope.clientStoragePreferencesTabSelected = false;
			$scope.clientStockControlPreferencesTabSelected = false;

			$rootScope.contentNavBar.buttons = [$rootScope.labels.SAVE];
			if (hasOtherClientWithPermission) {
				$rootScope.contentNavBar.buttons.push($scope.labels.REPLICATE_INFO);
			}
		};

		$scope.clientStoragePreferencesTabSelectedClick = function () {
			$scope.clientGeneralTabSelected = false;
			$scope.clientDisposalPreferencesTabSelected = false;
			$scope.clientStoragePreferencesTabSelected = true;
			$scope.clientStockControlPreferencesTabSelected = false;

			$rootScope.contentNavBar.buttons = [$rootScope.labels.SAVE];
			if (hasOtherClientWithPermission) {
				$rootScope.contentNavBar.buttons.push($scope.labels.REPLICATE_INFO);
			}
		};

		$scope.clientStockControlPreferencesTabSelectedClick = function () {
			$scope.clientGeneralTabSelected = false;
			$scope.clientDisposalPreferencesTabSelected = false;
			$scope.clientStoragePreferencesTabSelected = false;
			$scope.clientStockControlPreferencesTabSelected = true;

			$rootScope.contentNavBar.buttons = [$rootScope.labels.SAVE];
			if (hasOtherClientWithPermission) {
				$rootScope.contentNavBar.buttons.push($scope.labels.REPLICATE_INFO);
			}
		};

		function addReplicateCustomButtons() {
			$rootScope.contentNavBar.customButtons = [
				{
					label: $scope.labels.REPLICATE_INFO,
					buttonClasses: "md-primary",
					notRaised: true,
					supressToast: true,
					onClick: function () {
						return onReplicateBtnClick();
					}
				}
			];

			$rootScope.contentNavBar.buttons = [$rootScope.labels.SAVE, $scope.labels.REPLICATE_INFO];
		}

		function onReplicateBtnClick() {
			const messageTypeMap = {
				clientStockControlPreferencesTabSelected: Vgr.enumerations.messageTypes.REPLICATE_STOCK_CONTROL,
				clientDisposalPreferencesTabSelected: Vgr.enumerations.messageTypes.REPLICATE_DISPOSAL_PREFERENCES,
				clientStoragePreferencesTabSelected: Vgr.enumerations.messageTypes.REPLICATE_STORAGE_PREFERENCES
			};

			const selectedTab = Object.keys(messageTypeMap).find((tab) => $scope[tab]);
			if (selectedTab) {
				sendReplicateMessage(messageTypeMap[selectedTab]);
			}

			return $q.resolve();
		}

		function sendReplicateMessage(type) {
			document.getElementById("portalAngular").contentWindow.postMessage(
				{
					type: type,
					payload: "openModal"
				},
				Vgr.constants.newAngularUrl
			);
		}
	}
]);
