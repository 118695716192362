angular.module("vgresiduos").controller("DisposalHistoryGridCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"localStorageService",
	"httpService",
	"disposalService",
	"residuesManagementService",
	"clientService",
	"$q",
	"dialogService",
	"permissionService",
	"accountService",
	"userAccessService",
	"$window",
	"disposalPreferenceService",
	"engagementScoreService",
	function (
		$rootScope,
		$scope,
		$state,
		localStorageService,
		httpService,
		disposalService,
		residuesManagementService,
		clientService,
		$q,
		dialogService,
		permissionService,
		accountService,
		userAccessService,
		$window,
		disposalPreferenceService,
		engagementScoreService
	) {
		"use strict";
		const viewPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.ViewDisposal);
		const saveUnfinishedPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.CreateEditDeleteDisposalUnfinished
		);
		const saveFinishedPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditDeleteDisposalFinished);
		const hasAccessToCreateDisposalModel =
			Vgr.enumerations.userAccess.Enabled === userAccessService.getAccess(Vgr.systemPermissions.CrudDisposalModels);

		const decimalPlaces = clientService.getDecimalPlaces();
		const MaxTotalExportDisposals = 5000;

		let gridOrder;
		let gridQuery = null;

		const listResiduesPath = "ResiduesFormated";
		const listManifestsPath = "Manifests";
		const maxResidueToShow = 3;

		const columns = [
			{
				field: "Code",
				displayName: function () {
					return $rootScope.labels.commonFields.ID;
				},
				width: 10
			},
			{
				field: "StatusLabel",
				notSortable: true,
				displayName: function () {
					return $rootScope.labels.STATUS;
				}
			},
			{
				field: "FormatedGatheringDate",
				displayName: function () {
					return $rootScope.labels.GATHERING_DATE;
				},
				notSortable: true
			},
			{
				field: "DestinatorName",
				displayName: function () {
					return $rootScope.labels.DESTINATOR;
				},
				notSortable: true
			},
			{
				subfield: "MtrCode",
				displayName: function () {
					return $rootScope.labels.MTR;
				},
				sublist: listManifestsPath
			},
			{
				field: "InvoiceCode",
				displayName: function () {
					return $rootScope.labels.INVOICE;
				},
				notSortable: true
			},
			{
				subfield: "ResidueName",
				displayName: function () {
					return $rootScope.labels.RESIDUE;
				},
				sublist: listResiduesPath,
				icon: {
					path: "img/residuoPerigoso.GIF",
					condition: "Dangerous",
					tooltip: $rootScope.labels.DANGEROUS_RESIDUE
				}
			},
			{
				subfield: "QuantityString",
				displayName: function () {
					return $rootScope.labels.AMOUNT_ABBREVIATION;
				},
				sublist: listResiduesPath,
				notSortable: true
			},
			{
				field: "ResidueTags",
				displayName: function () {
					return $rootScope.labels.TAG;
				},
				isTag: true,
				notSortable: true
			},
			{
				field: "InformationsArray",
				displayName: function () {
					return $rootScope.labels.METADATA;
				},
				isList: true,
				notSortable: true
			},
			{
				editOptions: [
					{
						type: "custom",
						icon: "print",
						label: $scope.labels.GENERATE_DOCUMENTS,
						disable: function () {
							return false;
						},
						click: function (row) {
							openPopupPrintOut(row);
						}
					},
					{
						type: "custom",
						icon: "edit",
						label: $scope.labels.EDIT,
						disable: function (row) {
							if (row.Status == Vgr.enumerations.destination.status.Finalized) {
								return !saveFinishedPermission;
							} else {
								return !saveUnfinishedPermission;
							}
						},
						click: function (row) {
							$state.go("destinationEdit", { id: row.Id });
						}
					},
					{
						type: "custom",
						icon: "sticky_note_2",
						label: $scope.labels.CREATE_DISPOSAL_MODEL_FROM_DISPOSAL,
						disable: function () {
							return !hasAccessToCreateDisposalModel;
						},
						click: function (row) {
							const url = $state.href("destinationCreateModel", {
								disposal: row.Id
							});
							$window.open(url, "_blank");
						}
					},
					{
						type: "custom",
						icon: "delete",
						label: $scope.labels.DELETE,
						disable: function (row) {
							if (row.Status == Vgr.enumerations.destination.status.Finalized) {
								return !saveFinishedPermission;
							} else {
								return !saveUnfinishedPermission;
							}
						},
						click: function (row) {
							openDeleteDisposalPopup(row);
						}
					}
				],
				width: 25
			}
		];

		$scope.labels = $rootScope.labels;
		$scope.isFilterActive = false;
		$scope.filters = {};
		$scope.hasActiveFilters = false;
		const refreshLabel = "";
		$scope.contentNavBarOptions = {
			title: $scope.labels.DISPOSAL_HISTORY,
			customButtons: [
				{
					label: refreshLabel,
					buttonClasses: "button-refresh primary-button",
					icon: "refresh",
					supressToast: true,
					tooltip: $scope.labels.UPDATE,
					onClick: function () {
						const deferred = $q.defer();
						$rootScope.$broadcast(Vgr.constants.evtScreenDataRefresh);
						deferred.resolve();
						return deferred.promise;
					}
				},
				{
					label: $scope.labels.DISPOSAL_FOLLOWUP_BUTTON,
					buttonClasses: "primary-button lightest-bg",
					icon: "arrow_forward",
					supressToast: true,
					onClick: function () {
						const deferred = $q.defer();
						$state.go("disposalFollowup");
						deferred.resolve();
						return deferred.promise;
					}
				},
				{
					label: $scope.labels.CREATE_SIMPLIFIED_DISPOSAL_BUTTON,
					buttonClasses: "primary-button",
					icon: "add",
					supressToast: true,
					hasPermission: function () {
						return saveUnfinishedPermission;
					},
					onClick: function () {
						const deferred = $q.defer();
						$state.go("destinationNew", { origem: $scope.labels.ORIGIN_HISTORY_URL });
						deferred.resolve();
						return deferred.promise;
					}
				},
				{
					label: $scope.labels.ADD_CDF,
					buttonClasses: "primary-button",
					supressToast: true,
					hasPermission: function () {
						return saveUnfinishedPermission || saveFinishedPermission;
					},
					onClick: function () {
						const deferred = $q.defer();
						onClickAddMuiltipleCDFs();
						deferred.resolve();
						return deferred.promise;
					}
				},
				{
					label: $scope.labels.EXPORT,
					icon: "ios_share",
					buttonClasses: "md-primary",
					supressToast: true,
					onClick: function () {
						const deferred = $q.defer();
						exportDisposals();
						deferred.resolve();
						return deferred.promise;
					}
				}
			],
			validate: false,
			valid: true,
			buttons: [refreshLabel, $scope.labels.DISPOSAL_FOLLOWUP_BUTTON, $scope.labels.ADD_CDF, $scope.labels.EXPORT]
		};

		$scope.gridOptions = {
			enableSelect: saveUnfinishedPermission || saveFinishedPermission,
			columnDefinitions: columns,
			filterFields: [],
			filterSubFields: [],
			title: $rootScope.labels.EXTERNAL_MANAGEMENT,
			gridFilter: {
				text: ""
			},
			onShowAllFilters: function (open) {
				$scope.isFilterActive = open;
			},
			hasActiveFilters: function () {
				return $scope.hasActiveFilters;
			},
			rowClick: function (row) {
				$state.go("destinationEdit", { id: row.Id });
			},
			isChipsFilter: true,
			query: {
				order: "-Code",
				limit: 20,
				page: 1,
				serverPagination: true,
				onPaginate: function (page, limit, query, column, order, tagList, dateFilter) {
					$scope.gridOptions.query.page = page;
					$scope.gridOptions.query.limit = limit;
					loadList(query, column, order, tagList, dateFilter);
				},
				onReorder: function (query, column, order, tagList, dateFilter) {
					loadList(query, column, order, tagList, dateFilter);
				}
			},
			onFilter: function (query, column, order, tagList, dateFilter) {
				loadList(query, column, order, tagList, dateFilter);
			},
			data: [],
			newState: "externalGatheringNew.requestExternalGathering",
			promise: {},
			hasData: false,
			errorLoading: false,
			statusProperty: "DisposalStatus",
			removeDelete: true,
			removeNew: true
		};

		$scope.cleanFilters = function () {
			$scope.filters = {};
			gridQuery = null;
			$rootScope.$broadcast(Vgr.constants.evtCleanTagFilter);

			loadInitialList();
		};

		$scope.applyFilters = function (filters) {
			$scope.filters = filters;
			$rootScope.$broadcast(Vgr.constants.evtApplyAllFilters);

			loadListFromDto(gridQuery);
		};

		function onClickAddMuiltipleCDFs() {
			const selectedDisposals = $scope.gridOptions.selected;

			if ($scope.gridOptions.selected.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SELECT_AT_LEAST_ONE_DISPOSAL);
				return;
			}

			const params = {
				list: selectedDisposals
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/disposalHistory/popups/attachCDFToDisposalsPopup/attachCDFToDisposalsPopup_template.html",
					"AttachCDFToDisposalsPopupCtrl",
					"#large-popup",
					params
				)
				.then(
					function () {
						loadInitialList();
					},
					function () {
						loadInitialList();
					}
				);
		}

		function loadInitialList() {
			$scope.gridOptions.selected = [];
			loadList("", "", "DESC");
		}

		function loadClientDisposalPreferences() {
			const deferred = $q.defer();

			disposalPreferenceService.getDisposalPreferences().then(function (disposalPreferences) {
				deferred.resolve(disposalPreferences);
			});

			return deferred.promise;
		}

		function loadList(query, column, order, tagList, dateFilter) {
			gridOrder = order;
			gridQuery = query;
			loadListFromDto(query);
		}

		function getFilterParams(query) {
			const loggedClient = accountService.getCurrentClient();
			const params = {
				skip: $scope.gridOptions.query.page - 1,
				take: localStorageService.get("grid.pageLimit") || Vgr.constants.DEFAULT_ROWS_PER_PAGE,
				query: query,
				orderAsc: gridOrder == "ASC" ? true : false,
				organizationUnitCode: loggedClient.code
			};

			if ($scope.filters) {
				params.status = $scope.filters.status;
				params.invoice = $scope.filters.invoice;
				params.mtrCode = $scope.filters.mtrCode;
				params.area = $scope.filters.area;
				params.destinator = $scope.filters.destinator;
				params.requestedDateBegin = getFormattedFilterDate($scope.filters.requestedDateBegin);
				params.requestedDateEnd = getFormattedFilterDate($scope.filters.requestedDateEnd);
				params.receivedDateBegin = getFormattedFilterDate($scope.filters.receivedDateBegin);
				params.receivedDateEnd = getFormattedFilterDate($scope.filters.receivedDateEnd);
				params.gatheredDateBegin = getFormattedFilterDate($scope.filters.gatheredDateBegin);
				params.gatheredDateEnd = getFormattedFilterDate($scope.filters.gatheredDateEnd);
				params.disposalDateBegin = getFormattedFilterDate($scope.filters.disposalDateBegin);
				params.disposalDateEnd = getFormattedFilterDate($scope.filters.disposalDateEnd);
			}

			return params;
		}

		function getFormattedFilterDate(date) {
			return date ? Vgr.date.toISOString(date) : "";
		}

		function loadListFromDto(query) {
			$scope.gridOptions.hasData = false;

			const params = getFilterParams(query);

			const promise = httpService.getListFromServiceCore(residuesManagementService.listDisposalsHistory, params, true);
			promise.then(function (content) {
				$scope.gridOptions.data = content.list;
				$scope.gridOptions.query.total = content.count;
				formatValues();
				Vgr.date.fromDatesFromArrayToLStrings($scope.gridOptions.data);
				$scope.gridOptions.hasData = true;
			});
		}

		function formatValues() {
			for (const disposal of $scope.gridOptions.data) {
				setFormatedGatheringDate(disposal);
				formatStatus(disposal);
				formatAdditionalInformations(disposal);
				formatResidueInformations(disposal);
				formatResidueRow(disposal);
				formatMtrRow(disposal);
			}
		}

		function formatMtrRow(disposal) {
			const formatedMtrs = [];

			for (const manifest of disposal.Manifests) {
				if (manifest.MtrCode) {
					if (manifest.Source.Id != Vgr.enumerations.destination.systemSource.Local) {
						manifest.MtrCode = manifest.Source.Description + ": " + manifest.MtrCode;
					}
					formatedMtrs.push(manifest);
				}
			}

			disposal.Manifests = formatedMtrs;
		}

		function setFormatedGatheringDate(disposal) {
			if (disposal.GatheringDate) {
				disposal.FormatedGatheringDate = new Date(disposal.GatheringDate);
			} else {
				disposal.FormatedGatheringDate = "";
			}
		}

		function formatStatus(disposal) {
			disposal.StatusLabel = $scope.labels.DISPOSAL_STATUS_ENUM[Vgr.enumerations.destination.status[disposal.Status]];
		}

		function formatAdditionalInformations(disposal) {
			const informationsArray = [];
			if (disposal.Informations && disposal.Informations.length) {
				for (const information of disposal.Informations) {
					informationsArray.push(information.Property.Name + ": " + information.Value);
				}
			}
			disposal.InformationsArray = informationsArray;
		}

		function formatResidueInformations(disposal) {
			disposal.ResidueTags = [];
			for (const residue of disposal.Residues) {
				residue.QuantityString = "";
				const groupedQuantities = disposalService.groupDisposalQuantitieByMeasureUnit(residue.Quantities);
				for (const prop in groupedQuantities) {
					const groupedQuantity = groupedQuantities[prop];
					const totalQuantity = Vgr.util.sumPropArray(groupedQuantity, "Quantity");
					if (residue.QuantityString != "") {
						residue.QuantityString += "\n";
					}
					residue.QuantityString +=
						Vgr.util.formatUnitDecimalPlaces(totalQuantity, decimalPlaces) +
						" " +
						groupedQuantity[0].MeasureUnit.Abbreviation;
				}

				disposal.ResidueTags = disposal.ResidueTags.concat(getResidueTags(residue));
			}

			disposal.ResidueTags = [...new Set(disposal.ResidueTags)];
		}

		function formatResidueRow(disposal) {
			if (disposal.Residues.length <= maxResidueToShow) {
				disposal.ResiduesFormated = disposal.Residues;
			} else {
				disposal.ResiduesFormated = [];
				disposal.ResiduesFormated.push(disposal.Residues[0]);
				disposal.ResiduesFormated.push(disposal.Residues[1]);

				const otherResiduesQuantity = disposal.Residues.length - (maxResidueToShow - 1);

				disposal.ResiduesFormated.push({
					ResidueName: $scope.labels.MANY_RESIDUES_IN_DISPOSAL.replace("[PARAM1]", otherResiduesQuantity),
					QuantityString: "",
					Dangerous: false
				});
			}
		}

		function openDeleteDisposalPopup(disposal) {
			const params = {
				disposal: disposal
			};
			$scope.dialog = dialogService
				.showDialogFromTemplateV2(
					"views/disposalHistory/popups/deleteDisposalPopup/deleteDisposalPopup_template.html",
					"DeleteDisposalPopupCtrl",
					null,
					params
				)
				.then(
					function () {
						loadInitialList();
					},
					function () {}
				);
		}

		function getResidueTags(residue) {
			if (!residue.Tags) {
				return [];
			}
			const residueTags = [];
			for (const tag of residue.Tags) {
				residueTags.push(tag.Name);
			}

			return residueTags;
		}

		function openPopupPrintOut(model) {
			const dialog = dialogService
				.showDialogFromTemplateV2(
					"views/destination/popups/disposalReportsPopup/disposalReportsPopup_template.html",
					"DisposalReportsPopupCtrl",
					"#disposal-popup",
					{ destinationModel: model },
					true
				)
				.then(
					() => null,
					() => null
				);
			$scope.dialog = dialog;
		}

		this.$onInit = function () {
			if (!viewPermission) {
				accountService.goHome();
				return;
			}

			$rootScope.contentNavBar = $scope.contentNavBarOptions;
			$rootScope.contentNavBarGrid = $scope.gridOptions;

			loadClientDisposalPreferences().then(configureNavBarCustomButtons);
		};

		function configureNavBarCustomButtons(disposalPreferences) {
			$scope.contentNavBarOptions.buttons = [refreshLabel, $scope.labels.DISPOSAL_FOLLOWUP_BUTTON];
			if (disposalPreferences.isSimpleModel) {
				$scope.contentNavBarOptions.buttons.push($scope.labels.CREATE_SIMPLIFIED_DISPOSAL_BUTTON);
			}
			$scope.contentNavBarOptions.buttons.push($scope.labels.ADD_CDF, $scope.labels.EXPORT);
		}

		function exportDisposals() {
			updateExportButtonLoading(true);
			if (parseInt($scope.gridOptions.query.total) > MaxTotalExportDisposals) {
				openConfirmationExportPopup();
			} else {
				onExportDisposalsConfirm();
			}
		}

		function openConfirmationExportPopup() {
			const params = {
				popupTitle: $scope.labels.EXPORT_LIMIT,
				popupText: $scope.labels.DISPOSAL_HISTORY_LIMIT_EXPORT,
				popupAuxiliaryText: $scope.labels.FILTER_DISPOSAL_HISTORY_BEFORE_EXPORT,
				popupDescription: null,
				cancelButtonLabel: null,
				confirmButtonLabel: null,
				cancelMethod: function () {
					updateExportButtonLoading();
				},
				confirmMethod: function () {
					return onExportDisposalsConfirm();
				}
			};

			dialogService.showDialogFromTemplateV2(
				"views/components/popups/confirmPopup_template.html",
				"ConfirmPopupCtrl",
				null,
				params
			);
		}

		function updateExportButtonLoading(value = false) {
			$scope.contentNavBarOptions.customButtons[4].isLoading = value;
		}

		function onExportDisposalsConfirm() {
			engagementScoreService.track(Vgr.trackings.disposal.history.exportHistory);

			const deferred = $q.defer();
			httpService
				.postDTOToServiceV2(residuesManagementService.exportDisposalsHistory, getFilterParams(gridQuery))
				.then(function (content) {
					downloadReport(content);
				})
				.finally(function () {
					updateExportButtonLoading(false);
					deferred.resolve();
				});

			return deferred.promise;
		}

		function downloadReport(data) {
			if (data) {
				const fileDTO = Vgr.util.fileDtoToFile({
					Extension: data.extension,
					Name: data.name,
					FileBytes: data.fileBytes
				});
				// eslint-disable-next-line no-undef
				saveAs(fileDTO, fileDTO.FileName);
			}
		}
	}
]);
