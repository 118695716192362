angular.module("vgresiduos").controller("AddressFieldsetFormCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"locationService",
	function ($rootScope, $scope, httpService, locationService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.changeCountry = changeCountry;

		this.$onInit = function () {
			if ($scope.$ctrl.cityId) {
				getCity($scope.$ctrl.cityId);
			} else {
				ManageAddressFields($scope.$ctrl.countryId);
			}
		};

		function getCity(cityId) {
			httpService.getDTOFromServiceV2(locationService.getCity, cityId).then(function (cityResponse) {
				formatCity(cityResponse);
				$scope.$ctrl.city = cityResponse;
				ManageAddressFields($scope.$ctrl.countryId);
			});
		}

		function ManageAddressFields(countryId) {
			calculateIfShowsMask(countryId);
			setCityFieldLabel(countryId);
		}

		$scope.$watch("$ctrl.countryId", function (newValue, oldValue) {
			if (newValue != oldValue) {
				changeCountry(newValue);
			}
		});

		function changeCountry(value) {
			calculateIfShowsMask(value);
			setCityFieldLabel(value);
			clearAllFields(value);
		}

		function calculateIfShowsMask(value) {
			$scope.showCEPMask = value == Vgr.constants.country.Brazil;
		}

		function setCityFieldLabel(value) {
			if (value == undefined || value == Vgr.constants.country.Brazil) {
				$scope.labelCity = $scope.labels.CITY_STATE;
			} else {
				$scope.labelCity = $scope.labels.PROVINCIA_DEPARTAMENTO;
			}
		}

		function clearAllFields(isCountryChanged) {
			if (isCountryChanged) {
				$scope.$ctrl.neighborhood = null;
				$scope.$ctrl.cep = null;
				$scope.$ctrl.address = null;
				$scope.$ctrl.city = null;
			}
		}

		function formatCity(city) {
			if (city.name) {
				city.DescriptionToShow = Vgr.util.capitalizeText(city.name) + "- " + city.state.abbreviation;
			}
		}
	}
]);
