angular.module("vgresiduos").controller("DisposalAddIntegrationsPopupCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"dialogService",
	"disposalService",
	"asyncTimeoutService",
	"localVariables",
	function ($scope, $rootScope, $q, httpService, dialogService, disposalService, asyncTimeoutService, localVariables) {
		"use strict";

		let hasDeleteError;

		$scope.destinationModel = localVariables.destinationModel;
		$scope.readOnlyModel = localVariables.readOnlyModel;
		$scope.systems = localVariables.systems;
		$scope.updateIntegrationSystemsModels = localVariables.updateIntegrationSystemsModels;
		$scope.listClientExternalSystems = localVariables.listClientExternalSystems;
		$scope.loadings = localVariables.loadings;
		$scope.forcedAutoSelectSystems = localVariables.forcedAutoSelectSystems;
		$scope.openIntegrationsPopup = localVariables.openIntegrationsPopup;
		$scope.disableNewIntegrations = localVariables.disableNewIntegrations;

		$scope.labels = $rootScope.labels;
		$scope.resultIntegrationFinishWithError = false;

		$scope.systemsSocket = [];

		function getSystemsPossibleToCreateProvisional() {
			return $scope.destinationModel.ExternalSystems.filter((s) => s.isPossibleToCreateProvisionalMTR);
		}

		function openProvisionalMTRPopup(externalSystems) {
			$scope.externalSystemToCreateProvisionalMtrIds = externalSystems.map((a) => a.SourceId);

			const locals = {
				destinationModel: $scope.destinationModel,
				loadings: $scope.loadings,
				updateIntegrationSystemsModels: $scope.updateIntegrationSystemsModels,
				externalSystemToCreateProvisionalMtrIds: $scope.externalSystemToCreateProvisionalMtrIds
			};
			dialogService.showDialogFromTemplateV2(
				"views/destination/popups/disposalCreateProvisionalMTRPopup/disposalCreateProvisionalMTRPopup_template.html",
				"DisposalCreateProvisionalMTRPopupCtrl",
				null,
				locals
			);
		}

		function calculateIsPossibleToCreateProvisionalMTR(status, errors, system) {
			const externalSystem = $scope.destinationModel.ExternalSystems.find((es) => es.SourceId == system.SourceId);

			if (externalSystem.canUseProvisionalMtr && !isExportDisposal()) {
				if (errors && errors.length > 0) {
					const isErrorForPossibleToCreateProvisional =
						errors[0].Code == Vgr.errorCodes.disposal.SystemUnavailable ||
						errors[0].Code == Vgr.errorCodes.disposal.SystemInstable ||
						errors[0].Code == Vgr.errorCodes.disposal.SystemInstableOnCreation ||
						errors[0].Code == Vgr.errorCodes.disposal.InternalError ||
						(errors[0].Code == Vgr.errorCodes.disposal.InvalidLogin &&
							system.SourceId == Vgr.enumerations.destination.systemSource.IMA_SC) ||
						status === 500;

					if (isErrorForPossibleToCreateProvisional) {
						system.isPossibleToCreateProvisionalMTR = true;
						system.errorMessage = system.errorMessage.replace(
							$scope.labels.MTR_UPDATE_PROVISIONAL,
							$scope.labels.MTR_CREATE_PROVISIONAL
						);
					}
				}
			}
		}

		function isExportDisposal() {
			return $scope.destinationModel.Model == Vgr.enumerations.destination.model.Export;
		}

		function hidePopup() {
			dialogService.hide($scope.dialog);
		}

		$scope.goBack = function () {
			hidePopup();
		};

		$scope.confirm = function () {
			if ($scope.disableNewIntegrations) {
				return;
			}

			const list = $scope.destinationModel.ExternalSystems.filter(
				(s) =>
					s.Selected &&
					s.status != Vgr.enumerations.operationStatus.Success &&
					s.status != Vgr.enumerations.operationStatus.Forbidden &&
					s.status != Vgr.enumerations.operationStatus.BadRequestError
			);
			if (!list.length) {
				hidePopup();
				return;
			}
			hasDeleteError = false;
			if ($scope.forcedAutoSelectSystems && $scope.disableTemporarilyActionButtons) {
				$scope.disableTemporarilyActionButtons = false;
			}
			$scope.loadings.integration = true;
			$scope.destinationModel.ExternalSystems = [...list];
			addIntegrations(list).then(
				function () {
					updateManifests(true);
				},
				function () {
					updateManifests(false);
				}
			);
		};

		$scope.continue = function () {
			const systemsPossibleToCreateProvisional = getSystemsPossibleToCreateProvisional();
			if (systemsPossibleToCreateProvisional.length) {
				openProvisionalMTRPopup(systemsPossibleToCreateProvisional);
			} else {
				dialogService.hide($scope.dialog);
				$scope.openIntegrationsPopup();
			}
		};

		function updateManifests(allSuccess) {
			$scope.resultIntegrationFinishWithError = !allSuccess;
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(
				function (data) {
					$scope.destinationModel.Manifests = data.Disposal.Manifests;
					$scope.updateIntegrationSystemsModels();
					$scope.loadings.integration = false;

					if (allSuccess) {
						hidePopup();
					}
				},
				function () {
					$scope.loadings.integration = false;
					window.location.reload();
				}
			);
		}

		function addIntegrations(list) {
			const deferred = $q.defer();
			batchAddIntegration(deferred, list, 1);
			return deferred.promise;
		}

		function batchAddIntegration(deferred, list, itemsPerBatch) {
			const batchItemsToAdd = list.splice(0, itemsPerBatch);
			batchAdd(batchItemsToAdd).then(
				function () {
					onBatchFinish(deferred, list, itemsPerBatch);
				},
				function () {
					hasDeleteError = true;
					onBatchFinish(deferred, list, itemsPerBatch);
				}
			);
		}

		function onBatchFinish(deferred, list, itemsPerBatch) {
			if (list.length > 0) {
				batchAddIntegration(deferred, list, itemsPerBatch);
			} else {
				hasDeleteError ? deferred.reject() : deferred.resolve();
			}
		}

		function batchAdd(list) {
			const promises = getAddIntegrationPromises(list);
			return $q.all(promises);
		}

		function getAddIntegrationPromises(list) {
			const promises = [];

			promises.push(addIntegration(list[0]));

			return promises;
		}

		function addIntegration(system) {
			system.status = Vgr.enumerations.operationStatus.Processing;
			system.isPossibleToCreateProvisionalMTR = false;
			system.errorMessage = null;
			const dto = {
				disposalId: $scope.destinationModel.Id,
				organizationUnitCode: $scope.destinationModel.Client.Code,
				model: {
					SourceId: system.SourceId
				}
			};
			const deferred = $q.defer();

			listenForEvents(system, deferred);
			httpService.postDTOToServiceV2(disposalService.createDisposalIntegration, dto, true).then(
				function (disposalManifests) {
					if (disposalManifests && disposalManifests.some((dm) => dm.Errors && dm.Errors.length > 0)) {
						setSystemResponseError(disposalManifests[0].Errors, system);
						deferred.reject();
					} else if (disposalManifests && disposalManifests.some((dm) => dm.IntegrationsMtrId)) {
						console.log(disposalManifests[0].IntegrationsMtrId);
						asyncTimeoutService.startAsyncCallTimer(
							system,
							onTimeoutNoResponseFromServer,
							asyncTimeoutService.getTimeoutBySystem(system.SourceId, true) * disposalManifests.length,
							8
						);
					} else {
						system.status = Vgr.enumerations.operationStatus.Success;
						deferred.resolve();
					}
				},
				function (response) {
					onErrorCreateDisposalIntegration(response.status, response.data, system);
					unsubscribeTopic(system);
					deferred.reject(response);
				}
			);

			return deferred.promise;
		}

		function setSystemResponseError(errors, system) {
			system.errorMessage = disposalService.getIntegrationErrorMessageFromResponse(
				400,
				errors,
				system.SourceDescription
			);
			system.canRetry = false;
			system.status = Vgr.enumerations.operationStatus.BadRequestError;
			system.Selected = false;
			calculateIsPossibleToCreateProvisionalMTR(400, errors, system);
		}

		function updateDisposalManifestFromSystem(response, system, timedOut) {
			const responseManifest = response.Disposal.Manifests.filter((m) => m.Source.Id == system.SourceId);
			if (responseManifest) {
				if (responseManifest.some((manifest) => isPendingIntegration(manifest)) && !timedOut) {
					return;
				}

				if (responseManifest.some((manifest) => !manifest.MtrCode)) {
					const manifestWithError = responseManifest.find((manifest) => manifest.Errors && manifest.Errors.length > 0);
					const errors = manifestWithError ? manifestWithError.Errors : getInstabilityAsyncResponseError();
					onErrorCreateMtrAsync(errors, system);
				} else {
					onSuccessCreateMtrAsync(system);
				}
			}
		}

		function isPendingIntegration(manifest) {
			return (
				manifest.Status == Vgr.enumerations.destination.systemStatus.Pending &&
				manifest.MtrCode == null &&
				(!manifest.Errors || manifest.Errors.length == 0)
			);
		}

		function getInstabilityAsyncResponseError() {
			return [
				{
					Code: Vgr.errorCodes.disposal.SystemInstableOnCreation,
					Message: $scope.labels.INSTABILITY_ASYNC_RESPONSE_NO_REFRESH
				}
			];
		}

		function onErrorCreateDisposalIntegration(status, errors, system, canRetry) {
			system.errorMessage = disposalService.getIntegrationErrorMessageFromResponse(
				status,
				errors,
				system.SourceDescription
			);
			system.canRetry = canRetry;
			system.status =
				status == 400
					? Vgr.enumerations.operationStatus.BadRequestError
					: Vgr.enumerations.operationStatus.UnexpectedError;
			if (system.status != Vgr.enumerations.operationStatus.UnexpectedError) {
				system.Selected = false;
			}
			calculateIsPossibleToCreateProvisionalMTR(status, errors, system);
		}

		const onRetryCancel = $rootScope.$on("retry_cancel", function (data, system) {
			if ($scope.disableNewIntegrations) {
				return;
			}

			$scope.loadings.integration = true;
			addIntegrations([system]).then(
				function () {
					updateManifests(true);
				},
				function () {
					updateManifests(false);
				}
			);
		});

		function onErrorCreateMtrAsync(errors, system, canRetry) {
			if (system.status == Vgr.enumerations.operationStatus.Success) {
				return;
			}
			const errorsCapitalize = errors.map((e) => Vgr.util.capitalizeObjectKeys(e));
			const status = getStatusFromErrors(errorsCapitalize);
			onErrorCreateDisposalIntegration(status, errorsCapitalize, system, canRetry);
			system.deferred.reject();
			unsubscribeTopic(system);
		}

		function getStatusFromErrors(errors) {
			if (errors && errors.length > 0) {
				const badRequestError = errors.find((e) => e.Code && e.Code != "000");
				if (badRequestError) {
					return 400;
				}
			}
			return 500;
		}

		function onSuccessCreateMtrAsync(system) {
			if (system) {
				system.status = Vgr.enumerations.operationStatus.Success;
				system.deferred.resolve();
				unsubscribeTopic(system);
			}
		}

		$scope.disableActions = function () {
			return $scope.loadings.integration || $scope.disableTemporarilyActionButtons;
		};

		function initializePopup() {
			$scope.systemsToIgnore = [];

			$scope.systems.forEach((s) => {
				$scope.systemsToIgnore.push(s.SourceId);
			});

			if ($scope.forcedAutoSelectSystems) {
				$scope.disableTemporarilyActionButtons = true;
				$scope.onLoad = $scope.confirm;
			}
		}

		initializePopup();

		//init socket methods ---------------------------------------------------------------------------------------
		function getRoomName(disposalId, sourceId) {
			return "disposal_" + disposalId + "_source_" + sourceId;
		}

		function listenForEvents(system, deferred) {
			system = subscribeTopic(system);
			$scope.systemsSocket.push(system);
			system.deferred = deferred;
		}

		function onTimeoutNoResponseFromServer(system, timedOut) {
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(
				function (response) {
					updateDisposalManifestFromSystem(response, system, timedOut);
				},
				function () {
					onErrorCreateMtrAsync(getInstabilityAsyncResponseError(), system);
				}
			);
		}

		function cancelAllTimers(sourceId) {
			if (!$scope.systemsSocket) {
				return;
			}

			$scope.systemsSocket
				.filter((systemSocket) => !sourceId || (sourceId && systemSocket.SourceId == sourceId))
				.forEach((system) => {
					asyncTimeoutService.cancelAsyncTimer(system);
					unsubscribeTopic(system);
				});

			$scope.systemsSocket = [];
		}
		function subscribeTopic(system) {
			system.observable$ = window.Amplify.API.graphql(
				window.Amplify.graphqlOperation(Vgr.constants.graphql.subscriptions, {
					name: getRoomName($scope.destinationModel.Id, system.SourceId)
				})
			).subscribe({
				next: ({ value }) => callBackTopicSucces(JSON.parse(value.data.subscribe2channel.data)),
				error: (error) => {
					console.log(error);
				}
			});

			return system;
		}

		function callBackTopicSucces(data) {
			const system = $scope.systemsSocket.find((ss) => ss.SourceId == data.sourceId);
			asyncTimeoutService.cancelAsyncTimer(system);

			if (data.success) {
				onSuccessCreateMtrAsync(system);
			} else {
				onErrorCreateMtrAsync(data.errors, system);
			}
		}

		function unsubscribeTopic(sistem) {
			if (sistem.observable$) {
				sistem.observable$.unsubscribe();
			}
		}

		$scope.$on("$destroy", function () {
			// disable the listener
			onRetryCancel();
			cancelAllTimers();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
