angular.module("vgresiduos").factory("residueV2Service", [
	"$http",
	"accountService",
	"localStorageWithTimeoutService",
	"$q",
	function ($http, accountService, localStorageWithTimeoutService, $q) {
		"use strict";
		const getNBRListCacheKey = Vgr.constants.cacheKeysPrefix.NBRList;
		const getConama358ListCacheKey = Vgr.constants.cacheKeysPrefix.conama358List;
		const cacheTimeoutInMinutes = 43200;

		function getBaseOrganizationUrl() {
			const organizationUnit = accountService.getCurrentClient();
			const organizationId = organizationUnit.organization.id;
			return (
				Vgr.constants.coreHostUrl + Vgr.resources.core.residueOrganization.replace("[ORGANIZATION_ID]", organizationId)
			);
		}

		function getBaseOrganizationUnitUrl() {
			const organizationUnit = accountService.getCurrentClient();
			const organizationId = organizationUnit.organization.id;
			const organizationUnitCode = organizationUnit.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.residueOrganizationUnit
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			);
		}

		const _listOrganizationUnitResiduesOld = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);
			return $http({
				method: "get",
				url: getBaseOrganizationUnitUrl() + parameters
			});
		};

		const _listOrganizationResidues = function () {
			return $http({
				method: "get",
				url: getBaseOrganizationUrl()
			});
		};

		const _listSystemResidues = function (dto) {
			const parameters = Vgr.util.serializeObjectToQueryString(dto);
			return $http({
				method: "get",
				url: Vgr.constants.coreHostUrl + "v1/system-residues" + parameters
			});
		};

		//apiresidues

		function getResiduesBaseUrl() {
			const organizationUnit = accountService.getCurrentClient();
			const organizationId = organizationUnit.organization.id;
			return Vgr.constants.residuesHostUrl + Vgr.resources.residues.residues.replace("[ORGANIZATION]", organizationId);
		}

		function getOrganizationUnitResiduesBaseUrl() {
			const organizationUnit = accountService.getCurrentClient();
			const organizationId = organizationUnit.organization.id;
			const organizationUnitCode = organizationUnit.code;
			return (
				Vgr.constants.residuesHostUrl +
				Vgr.resources.residues.organizationUnitResidues
					.replace("[ORGANIZATION]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			);
		}

		const _listResidues = function () {
			return $http({
				method: "get",
				url: getResiduesBaseUrl()
			});
		};

		const _listOrganizationUnitResidues = function () {
			return $http({
				method: "get",
				url: getOrganizationUnitResiduesBaseUrl()
			});
		};

		const _getOrganizationUnitResidue = function (code) {
			return $http({
				method: "get",
				url: getOrganizationUnitResiduesBaseUrl() + "/" + code
			});
		};

		const _listResidueClasses = function () {
			return $http({
				method: "get",
				url: Vgr.constants.residuesHostUrl + Vgr.resources.residues.residueClass
			});
		};

		const _listPictograms = function () {
			return $http({
				method: "get",
				url: Vgr.constants.residuesHostUrl + Vgr.resources.residues.onuPictograms
			});
		};

		const _listNbrs = function () {
			const cacheKey = getNBRListCacheKey;
			const cachedEntities = localStorageWithTimeoutService.getFromCacheWithAutoExpiry(cacheKey);
			const deferred = $q.defer();

			if (cachedEntities) {
				deferred.resolve(cachedEntities);
			} else {
				$http({
					method: "get",
					url: Vgr.constants.residuesHostUrl + Vgr.resources.residues.nbrs
				}).then(
					function (list) {
						localStorageWithTimeoutService.setInCacheWithExpiry(cacheKey, list, cacheTimeoutInMinutes, null);
						deferred.resolve(list);
					},
					function () {
						deferred.reject();
					}
				);
			}
			return deferred.promise;
		};

		const _listConama358 = function () {
			const cacheKey = getConama358ListCacheKey;
			const cachedEntities = localStorageWithTimeoutService.getFromCacheWithAutoExpiry(cacheKey);
			const deferred = $q.defer();

			if (cachedEntities) {
				deferred.resolve(cachedEntities);
			} else {
				$http({
					method: "get",
					url: Vgr.constants.residuesHostUrl + Vgr.resources.residues.conama358
				}).then(
					function (list) {
						localStorageWithTimeoutService.setInCacheWithExpiry(cacheKey, list, cacheTimeoutInMinutes, null);
						deferred.resolve(list);
					},
					function () {
						deferred.reject();
					}
				);
			}
			return deferred.promise;
		};

		return {
			//apicore
			listOrganizationUnitResiduesOld: _listOrganizationUnitResiduesOld,
			listOrganizationResidues: _listOrganizationResidues,
			listSystemResidues: _listSystemResidues,

			//apiresidues
			listResidues: _listResidues,
			listOrganizationUnitResidues: _listOrganizationUnitResidues,
			getOrganizationUnitResidue: _getOrganizationUnitResidue,

			listResidueClasses: _listResidueClasses,
			listPictograms: _listPictograms,
			listNbrs: _listNbrs,
			listConama358: _listConama358
		};
	}
]);
