angular.module("vgresiduos").controller("MaturityAnswerEditCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `bi/eficiencia-ambiental/itens/${$stateParams.questionId}/editar`);
		};
	}
]);
