angular.module("vgresiduos").controller("DisposalResponsibleCtrl", [
	"$scope",
	"httpService",
	"userService",
	"accountService",
	function ($scope, httpService, userService, accountService) {
		"use strict";

		const loggedUser = accountService.getLoggedAccount();
		let isRequestDateValid = true;

		const responsibleWatch = $scope.$watch("destinationModel.Responsible", function (newVal, oldVal) {
			if (newVal != oldVal) {
				if ($scope.destinationModel.Responsible) {
					$scope.$emit(Vgr.constants.evtRefreshDisposalValidation);
				}
			}
		});

		this.$onInit = function () {
			loadClientResponsibles();
		};

		function loadClientResponsibles() {
			const dto = {
				organizationUnitCode: accountService.getCurrentClient().code
			};

			httpService.getDTOFromServiceV2(userService.listUsersV2, dto).then(
				function (list) {
					$scope.clientResponsibles = [];
					for (const user of list) {
						$scope.clientResponsibles.push(buildResponsible(user.id, user.name));
					}

					if ($scope.isCurrentStageReview() || $scope.showCurrentStageCompletedStep()) {
						$scope.destinationModel.Responsible = findResponsibleInList($scope.destinationModel.Responsible);
					} else {
						configureCreatingDefaultParams();
					}
				},
				function () {
					$scope.clientResponsibles = [];
				}
			);
		}

		function buildResponsible(id, name) {
			return {
				Id: id,
				Name: name
			};
		}

		function configureCreatingDefaultParams() {
			$scope.destinationModel.RequestDate = Vgr.util.getDate();
			const responsible = findResponsibleInList(
				$scope.destinationModel.Responsible
					? $scope.destinationModel.Responsible
					: buildResponsible(loggedUser.id, loggedUser.name)
			);

			if (responsible != null) {
				$scope.destinationModel.Responsible = responsible;
				if ($scope.destinationModel.OriginChannel == Vgr.enumerations.channel.disposal.disposalScreenHistory) {
					goToResponsibleStep();
				} else {
					goToResiduesStep();
				}
			} else {
				goToResponsibleStep();
			}
		}

		function goToResponsibleStep() {
			$scope.destinationConfig.currentStepPointer = $scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.A].Id;
			$scope.destinationConfig.enabledStepPointer = $scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.A].Id;
		}

		function goToResiduesStep() {
			$scope.destinationConfig.currentStepPointer = $scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.B].Id;
			$scope.destinationConfig.enabledStepPointer = $scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.B].Id;
		}

		$scope.responsibleSelected = function () {
			if (!$scope.clientResponsibles) {
				return null;
			}

			if ($scope.destinationModel.Responsible) {
				const responsible = findResponsibleInList($scope.destinationModel.Responsible);
				$scope.updateSlip($scope.stepsEnum.A, responsible.Name);
				if (isRequestDateValid) {
					$scope.enableStep($scope.stepsEnum.B);
				}
			} else {
				$scope.updateSlip($scope.stepsEnum.A, null);
			}
		};

		function findResponsibleInList(responsible) {
			if (!responsible) {
				return;
			}
			let foundReponsible = $scope.clientResponsibles.find((r) => r.Id == responsible.Id);

			if (!foundReponsible && $scope.isCurrentStageReview()) {
				foundReponsible = buildResponsible(responsible.Id, responsible.Name);
				$scope.clientResponsibles.push(foundReponsible);
			}

			return foundReponsible;
		}

		$scope.requestDateChanged = function () {
			$scope.validateRequestDate().then(
				function () {
					isRequestDateValid = true;
					$scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.A].CustomValidationMessage = null;
					$scope.responsibleSelected();
				},
				function (validation) {
					isRequestDateValid = false;
					$scope.destinationConfig.currentStage.Steps[$scope.stepsEnum.A].CustomValidationMessage =
						$scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[validation.Type];
					$scope.enableBackToStep($scope.stepsEnum.A);
				}
			);
		};

		$scope.$on("$destroy", function () {
			responsibleWatch();
		});
	}
]);
