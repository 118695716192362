angular.module("vgresiduos").controller("DisposalResiduesCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"dialogService",
	"httpService",
	"disposalService",
	function ($rootScope, $scope, $q, dialogService, httpService, disposalService) {
		"use strict";

		const isToBlockResiduesEdition = !$scope.editPermission || $scope.isToBlockFieldsBasedOnDisposalDate;

		this.$onInit = function () {
			$scope.residuesGrid = {
				columns: [
					{
						description: $scope.labels.ID,
						property: "Code"
					},
					{
						description: $scope.labels.RESIDUE_NAME,
						property: "ResidueName",
						getIcon: function (row) {
							return row && row.IsDangerous ? "img/residuoPerigoso.GIF" : null;
						},
						getTooltip: function (row) {
							return row && row.IsDangerous ? $scope.labels.DANGEROUS_RESIDUE : null;
						}
					},
					{
						description: $scope.labels.DISPOSAL_TECH,
						property: "DisposalTypeDescription"
					},
					{
						description: $scope.labels.QTDE,
						property: "QuantityString"
					},
					{
						description: $scope.labels.UNIT,
						property: "MeasureUnitAbbreviation"
					},
					{
						description: $scope.labels.PACKAGING,
						property: "RecipientFormattedToGrid"
					}
				],
				defaultActions: null,
				actionButtons: null,
				btnAddClick: function () {
					const newResidueRow = {
						GridKey: Vgr.util.uuid(),
						Id: Vgr.util.uuid()
					};

					return openDisposalResiduePopup(newResidueRow);
				}
			};

			setDefaultActions();
			if (!$scope.isCreating) {
				validateDisposalResidues();
			}
		};

		function setDefaultActions() {
			let openDisposalResiduePopupLabel;
			let openDisposalResiduePopupIcon;
			if (!isToBlockResiduesEdition && !disableEdition()) {
				$scope.residuesGrid.defaultActions = ["add", "delete"];
				openDisposalResiduePopupLabel = $scope.labels.EDIT;
				openDisposalResiduePopupIcon = "edit";
			} else {
				openDisposalResiduePopupLabel = $scope.labels.VIEW;
				openDisposalResiduePopupIcon = "remove_red_eye";
			}
			$scope.residuesGrid.actionButtons = [
				{
					label: openDisposalResiduePopupLabel,
					icon: openDisposalResiduePopupIcon,
					click: function (row) {
						return openDisposalResiduePopup(row);
					}
				}
			];
		}

		function disableEdition() {
			return (
				$scope.disposalPreferences.lockEditResidueWhenDisposalIntegrated &&
				$scope.hasAnyIntegratedManifest() &&
				isGatheredOrTemporaryStorageReceived()
			);
		}

		function isIntegratedAndReceive() {
			return $scope.hasAnyIntegratedManifest() && !isGatheredOrTemporaryStorageReceived();
		}

		function isGatheredOrTemporaryStorageReceived() {
			return (
				($scope.destinationModel.Status == Vgr.enumerations.destination.status.Requested ||
					$scope.destinationModel.Status == Vgr.enumerations.destination.status.Gathered ||
					$scope.destinationModel.Status == Vgr.enumerations.destination.status.TemporaryStorageReceived) &&
				!$scope.isArrivalOrConfirmationStage()
			);
		}

		function onResiduePopupSave(disposalResidue, isEditing) {
			const currentListWithoutIncomingDisposalResidue = getCurrentListWithoutDisposalResidue(disposalResidue);
			$scope.destinationModel.Residues = currentListWithoutIncomingDisposalResidue;
			addDisposalResidue(buildGridResidue(disposalResidue));
			validateDisposalResidues(isEditing);
		}

		function addDisposalResidue(gridResidue) {
			for (const disposalResidueQuantity of gridResidue) {
				$scope.destinationModel.Residues.push(disposalResidueQuantity);
			}
		}

		function getCurrentListWithoutDisposalResidue(disposalResidue) {
			const list = [];

			for (const existingDisposalResidue of $scope.destinationModel.Residues) {
				if (existingDisposalResidue.Id != disposalResidue.Id) {
					list.push(existingDisposalResidue);
				}
			}

			return list;
		}

		function buildGridResidue(disposalResidue) {
			const gridResidue = [];

			const baseGridResidue = {
				Id: disposalResidue.Id,
				GridKey: disposalResidue.GridKey || Vgr.util.uuid(),
				Code: disposalResidue.Residue.Code,
				ResidueId: disposalResidue.Residue.Id,
				ResidueOrganizationId: disposalResidue.Residue.ResidueId,
				ResidueName: disposalResidue.Residue.Name,
				DisposalTypeId: disposalResidue.DisposalType ? disposalResidue.DisposalType.Id : null,
				DisposalTypeDescription: disposalResidue.DisposalType ? disposalResidue.DisposalType.Description : null,
				Quantity: null,
				QuantityString: null,
				MeasureUnitId: null,
				MeasureUnitAbbreviation: null,
				RecipientId: disposalResidue.Recipient.id,
				RecipientName: disposalResidue.Recipient.description,
				RecipientFormattedToGrid:
					Vgr.util.formatUnitDecimalPlaces(disposalResidue.RecipientQuantity, 1) +
					" - " +
					disposalResidue.Recipient.description,
				RecipientQuantity: disposalResidue.RecipientQuantity,
				RecipientGuidId: disposalResidue.RecipientGuidId,
				IsDangerous: disposalResidue.Residue.Dangerous,
				AreaCode: disposalResidue.Area ? disposalResidue.Area.code : null,
				ControlsStock: disposalResidue.Residue.ControlsStock,
				Residue: disposalResidue.Residue,
				Quantities: disposalResidue.Quantities,
				StockAdjustmentType: disposalResidue.StockAdjustmentType,
				RecipientPercentualEfficiency: disposalResidue.RecipientPercentualEfficiency,
				Storages: disposalResidue.Storages,
				ResidueGuidId: disposalResidue.Residue.ResidueId,
				CadriDocumentId: disposalResidue.CadriDocumentId,
				InternalGatheringRecipientQuantities: disposalResidue.InternalGatheringRecipientQuantities
			};

			if (disposalResidue.Quantities.length == 0) {
				gridResidue.push(baseGridResidue);
			} else {
				const duplicatedResidues = $scope.getQuantitiesToGrid(baseGridResidue, disposalResidue.Quantities);
				if (duplicatedResidues.length > 0) {
					gridResidue.push(...duplicatedResidues);
				}
			}

			return gridResidue;
		}

		function openDisposalResiduePopup(residueRow) {
			const params = getResiduePopupParams(residueRow);

			const deferred = $q.defer();
			dialogService
				.showDialogFromTemplateV2(
					"views/destination/popups/disposalResiduePopup/disposalResiduePopup_template.html",
					"DisposalResiduePopupCtrl",
					"#disposal-popup",
					params
				)
				.then(
					function (disposalResidue) {
						onResiduePopupSave(disposalResidue, !!residueRow.ResidueId);
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);
			return deferred.promise;
		}

		function getResiduePopupParams(residueRow) {
			return {
				residueToPopup: residueRow,
				readOnlyModelResidues: $scope.readOnlyModel.Residues,
				disposalResidues: $scope.destinationModel.Residues,
				disposalId: $scope.destinationModel.Id,
				disposalModel: $scope.destinationModel.Model,
				isQuantityMandatory: $scope.isQuantityMandatory(),
				referenceDate: getReferenceDateForStock(),
				editPermission: !isToBlockResiduesEdition,
				stockControlPreferences: $scope.stockControlPreferences,
				disableEdition: disableEdition(),
				isIntegratedAndReceive: isIntegratedAndReceive(),
				disposalStatus: $scope.destinationModel.Status,
				isHistoryOrigin: $scope.isOriginHistory()
			};
		}

		function getReferenceDateForStock() {
			return $scope.destinationModel.GatheringDate != null
				? $scope.destinationModel.GatheringDate
				: $scope.destinationModel.RequestDate;
		}

		function getSlipDescription() {
			let slipDescription = "";
			for (const residue of $scope.destinationModel.Residues) {
				if (!slipDescription.includes(residue.ResidueName)) {
					slipDescription += residue.ResidueName + "\n";
				}
			}

			return slipDescription;
		}

		const onClientExternalSystemChanged = $rootScope.$on(
			Vgr.constants.evtClientExternalSystemChanged,
			function (_event, disposalId) {
				if (disposalId != $scope.destinationModel.Id) {
					return;
				}
				validateDisposalResidues();
			}
		);

		const residuesWatch = $scope.$watch("destinationModel.Residues.length", function (newVal, oldVal) {
			if (newVal != oldVal) {
				validateDisposalResidues(true);
			}
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			onClientExternalSystemChanged();
			residuesWatch();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});

		function updateSlipToSolicitation(valid, validationMessage) {
			if (valid) {
				$scope.updateSlip($scope.stepsEnum.B, getSlipDescription());
				$scope.enableStep($scope.stepsEnum.C);
			} else {
				$scope.updateSlip($scope.stepsEnum.B, null);
				$scope.enableBackToStep($scope.stepsEnum.B);
			}
			$scope.stagesConfig[$scope.stagesEnum.Solicitation].Steps[$scope.stepsEnum.B].CustomValidationMessage =
				validationMessage;

			if (!$scope.destinationModel.Responsible || !$scope.destinationModel.Responsible.Id) {
				$scope.enableBackToStep($scope.stepsEnum.A);
			}
		}

		function updateSlipToGathering(valid, validationMessage) {
			$scope.updateSlip($scope.stepsEnum.E, getSlipDescription());
			if (valid) {
				$scope.enableStep($scope.stepsEnum.F);
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.E].MiddleActionEnabled = true;
			} else {
				$scope.enableBackToStep($scope.stepsEnum.E);
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.E].MiddleActionEnabled = false;
			}

			if (!$scope.model.disposalGatheringIsLocked) {
				$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[$scope.stepsEnum.E].CustomValidationMessage =
					validationMessage;
			}
		}

		function updateSlipToArrival(valid, validationMessage) {
			if (valid) {
				$scope.updateSlip($scope.stepsEnum.A, getSlipDescription());
				$scope.enableStep($scope.stepsEnum.B);
			} else {
				$scope.updateSlip($scope.stepsEnum.A, null);
				$scope.enableBackToStep($scope.stepsEnum.A);
			}
			$scope.stagesConfig[$scope.stagesEnum.Arrival].Steps[$scope.stepsEnum.A].CustomValidationMessage =
				validationMessage;
		}

		function updateSlipToTemporaryStorage(valid, validationMessage) {
			if (valid) {
				$scope.updateSlip($scope.stepsEnum.A, getSlipDescription());
				$scope.enableStep($scope.stepsEnum.B);
			} else {
				$scope.updateSlip($scope.stepsEnum.A, null);
				$scope.enableBackToStep($scope.stepsEnum.A);
			}
			$scope.stagesConfig[$scope.stagesEnum.InTemporaryStorage].Steps[$scope.stepsEnum.A].CustomValidationMessage =
				validationMessage;
		}

		function calculateAsyncValidation(validations) {
			let validationMessage = "";

			for (const validation of validations) {
				if (validation.IsToBlock) {
					if (validationMessage) {
						validationMessage += "\n";
					}
					validationMessage += $scope.getCustomValidationMessage(validation);
				}
			}

			return {
				IsValid: validationMessage ? false : true,
				Message: validationMessage ? validationMessage : null
			};
		}
		function calculateLocalValidation() {
			const validationObject = {
				IsValid: true,
				Message: null
			};

			if ($scope.isSolicitationStage() || $scope.isGatheringStage()) {
				validationObject.IsValid = $scope.destinationModel.Residues.length != 0;
				validationObject.Message = validationObject.IsValid ? null : $scope.labels.DISPOSAL_RESIDUES_VALIDATION_MIN_ONE;
			} else if ($scope.isArrivalStage()) {
				const invalidResidue = $scope.destinationModel.Residues.find((residue) => !residue.Quantity);
				validationObject.IsValid = invalidResidue ? false : true;
				validationObject.Message = validationObject.IsValid
					? null
					: $scope.labels.DISPOSAL_RESIDUES_VALIDATION_ALL_QUANTITY;
			}

			return validationObject;
		}

		function validateAsync() {
			const deferrend = $q.defer();

			const dto = $scope.createValidateDto($scope.destinationModel.Transporter);
			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(
				function (response) {
					let validationObject = calculateAsyncValidation(response.Validations);
					if (validationObject.IsValid) {
						validationObject = calculateLocalValidation();
					}
					$scope.updateValidationList(response.Validations);
					deferrend.resolve(validationObject);
				},
				function () {
					deferrend.reject();
				}
			);

			return deferrend.promise;
		}

		function validateDisposalResidues(emitResidueChangedEvent) {
			if ($scope.isSolicitationStage()) {
				updateSlipToSolicitation(true, null);
				if (emitResidueChangedEvent) {
					$rootScope.$broadcast(Vgr.constants.evtDisposalResiduesUpdated, $scope.destinationModel.Id);
				}
			} else {
				if (!$scope.canValidateDisposal()) {
					return;
				}

				validateAsync().then(function (validationObject) {
					const valid = validationObject.IsValid;
					const validationMessage = validationObject.Message;
					if ($scope.isGatheringStage()) {
						updateSlipToGathering(valid, validationMessage);
					} else if ($scope.isTemporaryStorageStage()) {
						updateSlipToTemporaryStorage(valid, validationMessage);
					} else if ($scope.isArrivalStage()) {
						updateSlipToArrival(valid, validationMessage);
					}
					if (emitResidueChangedEvent) {
						$rootScope.$broadcast(Vgr.constants.evtDisposalResiduesUpdated, $scope.destinationModel.Id);
					}
				});
			}
		}
	}
]);
